import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import TableManager from '../../components/TableManager';
import { getAgentKpis } from './actions';

const AgentKpi = () => {
  const columns = [
    {
      accessorKey: 'signedDate',
      header: () => 'Agent',
      columns: [
        {
          accessorKey: 'full_name',
          accessorFn: (row) => `${row.firstName} ${row.lastName}`,
          header: () => 'Name',
          cell: (props) => {
            const element = props.row.original;
            const fullName = `${element?.agentDetail?.firstName || ''} ${
              element?.agentDetail?.lastName || ''
            }`;
            return (
              <span className=" text--danger font--600 d--flex gap--sm d--flex align-items--center justify-content--start">
                {fullName}
              </span>
            );
          },
          meta: {
            textAlign: 'left',
          },
        },
        {
          accessorKey: 'agentDetail.email',
          header: () => 'Email',
        },
        {
          accessorKey: 'agentDetail.phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'NPN',
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 4,
        textAlign: 'center',
      },
    },

    // {
    //   accessorKey: 'signedDate',
    //   header: () => <span className="font--sm font--600">HRA</span>,
    //   columns: [
    //     {
    //       accessorKey: 'hraSend',
    //       header: () => 'Sent',
    //       cell: (props) => {
    //         return (
    //           <div
    //             onClick={() => {}}
    //             className=" bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
    //           >
    //             {props.getValue()}
    //           </div>
    //         );
    //       },
    //       meta: {
    //         textAlign: 'center',
    //         color: '#00008B',
    //       },
    //     },
    //     {
    //       accessorKey: 'hraCompleted',
    //       header: () => 'Completed',
    //       cell: (props) => {
    //         return (
    //           <div
    //             onClick={() => {}}
    //             className=" bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
    //           >
    //             {props.getValue()}
    //           </div>
    //         );
    //       },
    //       meta: {
    //         textAlign: 'center',
    //         color: '#00008B',
    //       },
    //     },
    //   ],
    //   meta: {
    //     colSpan: 2,
    //     textAlign: 'center',
    //     color: '#00008B',
    //   },
    // },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consent</span>,
      columns: [
        {
          accessorKey: 'consentSend',
          header: () => 'Sent',
          cell: (props) => {
            return (
              <div className=" bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto">
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'consentSigned',
          header: () => 'Signed',
          cell: (props) => {
            return (
              <div className=" bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto">
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#1a9112',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Eligibility</span>,
      columns: [
        {
          accessorKey: 'eligibilitySend',
          header: () => 'Sent',
          cell: (props) => (
            <div className=" bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto">
              {props.getValue()}
            </div>
          ),

          meta: {
            textAlign: 'center',
            color: '#ffa000',
          },
        },
        {
          accessorKey: 'eligibilitySigned',
          header: () => 'Signed',
          cell: (props) => {
            return (
              <div className=" bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto">
                {props.getValue()}
              </div>
            );
          },
          meta: {
            textAlign: 'center',
            color: '#ffa000',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#ffa000',
      },
    },
  ];

  const formActions = [
    {
      id: 1,
      access: true,
      component: <FormInput placeholder="Agent NPN" />,
    },
    {
      id: 2,
      access: true,
      component: (
        <FormSelect
          // placeholder=" Year"
          options={[
            // {
            //   label: '2022',
            //   value: '2022',
            // },
            // {
            //   label: '2023',
            //   value: '2023',
            // },
            {
              label: '2024',
              value: '2024',
            },
            // {
            //   label: '2025',
            //   value: '2025',
            // },
            // {
            //   label: '2026',
            //   value: '2026',
            // },
          ]}
          height="40"
        />
      ),
    },
  ];

  return (
    <>
      <div className="w--full d--flex flex--column  invitationPage">
        <TableManager
          {...{
            fetcherKey: 'getAgentKpis',
            fetcherFn: getAgentKpis,
            shouldFilter: false,
            columns,
            formActions,
          }}
        />
      </div>
    </>
  );
};

export default AgentKpi;
