import { useCallback, useState } from 'react';
import Button from '../../components/Button';
import TableManager from '../../components/TableManager';
import AddMember from './AddMember';
import { getMemberEnrollmentList, getMemberList } from './actions';
import AsideModal from '../../components/AsideModal';
import Modal from '../../components/Modal';
import { initialEditInstance } from '../../helpers/constants';
import Dropdown from '../../components/Dropdown';
import { convertDateFunction, getOS } from '../../helpers/functions';
import SendDocModal from './Modal/SendDocModal';
import useIcons, {
  DotVetical,
  EyeIcon,
  UploadCloud,
} from '../../assets/icons/useIcons';
import ImportMember from './Modal/ImportMember';
import UploadDrive from '../UploadDrive';
import { filterDefaultValues } from './constants';
import UploadConsentDoc from './Modal/UploadConsentDoc';
import UploadToDrive from './Modal/UploadToDrive';
import HRAView from '../HRA/HRAView';
import { useAuth } from '../../hooks/useAuth';

const initialDriveState = { isOpen: false, data: null };
const reasonLookUp = {
  CONSENT: 'Consent',
  APP_REVIEW: 'App Review',
  CHANGE_APPROVAL: 'Change Approval',
  MULTI_TYPE: 'Multi Type',
};

const Members = () => {
  const { AddIcon, ImportIcon } = useIcons();
  const [openModal, setOpenModal] = useState(false);
  const [openAsideModal, setOpenAsideModal] = useState(false);
  const [editMemberInstance, setEditMemberInstance] =
    useState(initialEditInstance);
  const [drive, setDrive] = useState(initialDriveState);

  const { planDetails } = useAuth();

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setOpenAsideModal(false);
    setEditMemberInstance(initialEditInstance);
  }, []);

  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'send':
        setOpenModal('send_doc');
        setEditMemberInstance({
          editData: rowData,
          isEdit: true,
        });
        break;
      case 'edit':
        setOpenAsideModal(true);

        setEditMemberInstance({
          editData: rowData,
          isEdit: true,
        });
        break;
      case 'HRA':
        setOpenAsideModal('HRA');

        setEditMemberInstance({
          editData: rowData,
          isEdit: true,
        });
        break;
      case 'delete':
        // navigate('integrations');
        break;
      case 'upload':
        setDrive({
          data: rowData,
          isOpen: true,
        });
        break;
      // case 'view':
      //   if (rowData?.eligibilitySubmittedDate) {
      //   } else if (rowData?.signedDate) {
      //     window.open(
      //       `/view-document/${
      //         item?.id === 2
      //           ? rowData?.eligibilityAgreementUuid
      //           : rowData?.agreementUuid
      //       }?docType=${item?.id === 2 ? 'ELIGIBILITY' : 'CMS'}`,
      //     );
      //   } else {
      //     window.open(
      //       `/view-signature-document/${
      //         item?.id === 2
      //           ? rowData?.eligibilityAgreementUuid
      //           : rowData?.agreementUuid
      //       }?docType=${item?.id === 2 ? 'ELIGIBILITY' : 'CMS'}`,
      //     );
      //   }

      //   break;

      case 'consent-doc':
        setOpenModal('consent_doc');
        setEditMemberInstance({
          editData: rowData,
          isEdit: true,
        });

        break;
      case 'eligibility-doc':
        setOpenModal('eligibility_doc');
        setEditMemberInstance({
          editData: rowData,
          isEdit: true,
        });

        break;

      default:
        break;
    }
  };

  const dropEl = (
    <div className="d--flex align-items--center font--sm font--600 gap--sm text--black-800">
      <div className="w-min--32 h-min--32 h-max--32 w-max--32 radius--full text--black text--white d--flex align-items--center justify-content--center ">
        <DotVetical fallbackWidth={18} fallbackHeight={18} />
      </div>
    </div>
  );
  const columns = [
    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'firstName',
          header: () => 'Name',
          cell: (props) =>
            `${props.getValue()} ${props.row.original?.lastName}`,
          meta: {
            width: '30%',
          },
        },
        {
          accessorKey: 'phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'email',
          header: () => 'Email',
          meta: {
            width: '25%',
          },
        },
      ],
      meta: {
        colSpan: 3,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consent</span>,
      columns: [
        {
          accessorKey: 'submittedDate',
          header: () => 'Submitted Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.submittedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  {!rowData.signedDate && (
                    <span
                      className="d--flex align-items--center justify-content--center c--pointer"
                      onClick={() => {
                        window.open(
                          `/view-signature-document/${rowData?.agreementUuid}?docType=CMS`,
                        );
                      }}
                    >
                      <EyeIcon width={15} height={15} />
                    </span>
                  )}
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'signedDate',
          header: () => 'Signed Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.signedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `/view-document/${rowData?.agreementUuid}?docType=CMS`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#1a9112',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Eligibility</span>,
      columns: [
        {
          accessorKey: 'eligibilitySubmittedDate',
          header: () => 'Submitted Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.eligibilitySubmittedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  {!rowData.eligibilitySignedDate && (
                    <span
                      className="d--flex align-items--center justify-content--center c--pointer"
                      onClick={() => {
                        window.open(
                          `/view-signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                        );
                      }}
                    >
                      <EyeIcon width={15} height={15} />
                    </span>
                  )}
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#ffa000',
          },
        },
        {
          accessorKey: 'eligibilitySignedDate',
          header: () => 'Signed Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.eligibilitySignedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `/view-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#ffa000',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#ffa000',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => '',
      columns: [
        {
          accessorKey: 'enrollmentYear',
          header: () => 'Enrollment',

          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
        {
          accessorKey: 'reason',
          header: () => 'Reason',
          cell: ({ getValue }) => reasonLookUp[getValue()],
          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },

        {
          accessorKey: 'action',
          header: () => 'Action',
          cell: (props) => {
            return (
              <>
                <div className="w-min--125 w-min--auto-xs">
                  <Dropdown
                    closeOnClickOutside={true}
                    dropList={{
                      component: ({ item }) => {
                        if (!item?.access) return;
                        return (
                          <div
                            className="text--secondary p--sm w--full text--l"
                            onClick={() =>
                              handleProfileDropClick(
                                item?.value,
                                props.row?.original,
                                item,
                              )
                            }
                          >
                            {item?.title}
                          </div>
                        );
                      },

                      data: [
                        {
                          title: 'Send Docs',
                          value: 'send',
                          id: 1,
                          access: planDetails?.accessPermission,
                        },
                        // {
                        //   title: 'View Eligibility',
                        //   id: 2,
                        //   value: 'view',
                        //   access: !props?.row?.original?.submittedDate
                        //     ? false
                        //     : props?.row?.original?.eligibilitySubmittedDate
                        //     ? true
                        //     : false,
                        // },
                        // {
                        //   id: 3,
                        //   title: 'View Consent',
                        //   value: 'view',
                        //   access: !props?.row?.original?.submittedDate
                        //     ? false
                        //     : props?.row?.original?.submittedDate
                        //     ? true
                        //     : false,
                        // },
                        // {
                        //   id: 4,
                        //   title: 'Upload',
                        //   value: 'upload',
                        //   access:
                        //     props?.row?.original?.signedDate ||
                        //     props?.row?.original?.eligibilitySignedDate ||
                        //     false,
                        // },
                        // {
                        //   title: 'Delete',
                        //   value: 'delete',
                        //   id: 3,
                        //   access: true,
                        // },

                        {
                          id: 5,
                          title: 'Upload Consent',
                          value: 'consent-doc',
                          access:
                            planDetails?.accessPermission &&
                            !props?.row?.original?.signedDate,
                        },
                        {
                          id: 6,
                          title: 'Upload Eligibility',
                          value: 'eligibility-doc',
                          access:
                            planDetails?.accessPermission &&
                            !props?.row?.original?.eligibilitySignedDate,
                        },
                        {
                          title: 'Edit Consumer',
                          value: 'edit',
                          id: 4,
                          access:
                            !props?.row?.original?.signedDate &&
                            planDetails?.accessPermission,
                        },
                        // {
                        //   title: 'View HRA',
                        //   value: 'HRA',
                        //   id: 7,
                        //   access: props?.row?.original?.hra === 1,
                        // },
                      ],
                    }}
                  >
                    {dropEl}
                  </Dropdown>
                </div>
              </>
            );
          },
          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
        colSpan: 3,
      },
    },
  ];
  const moreOptioncolumns = [
    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'firstName',
          header: () => 'Name',
          // cell: (props) =>
          //   `${props.getValue()} ${props.row.original?.lastName}`,
        },
        {
          accessorKey: 'phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'email',
          header: () => 'Email',
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 3,
        textAlign: 'center',
        // border: "none",
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consent</span>,
      columns: [
        {
          accessorKey: 'submittedDate',
          header: () => 'Submitted Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.submittedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  {!rowData.signedDate && (
                    <span
                      className="d--flex align-items--center justify-content--center c--pointer"
                      onClick={() => {
                        window.open(
                          `/view-signature-document/${rowData?.agreementUuid}?docType=CMS`,
                        );
                      }}
                    >
                      <EyeIcon width={15} height={15} />
                    </span>
                  )}
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'signedDate',
          header: () => 'Signed Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.signedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `/view-document/${rowData?.agreementUuid}?docType=CMS`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#1a9112',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Eligibility</span>,
      columns: [
        {
          accessorKey: 'eligibilitySubmittedDate',
          header: () => 'Submitted Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.eligibilitySubmittedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  {!rowData.eligibilitySignedDate && (
                    <span
                      className="d--flex align-items--center justify-content--center c--pointer"
                      onClick={() => {
                        window.open(
                          `/view-signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                        );
                      }}
                    >
                      <EyeIcon width={15} height={15} />
                    </span>
                  )}
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#d32f2f',
          },
        },
        {
          accessorKey: 'eligibilitySignedDate',
          header: () => 'Signed Date',
          cell: (props) => {
            const rowData = props?.row?.original;
            if (rowData.eligibilitySignedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(props?.getValue())}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `/view-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            color: '#d32f2f',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#d32f2f',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => '',
      columns: [
        {
          accessorKey: 'enrollmentYear',
          header: () => 'Enrollment Year',

          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'reason',
      header: () => '',
      columns: [
        {
          accessorKey: 'reason',
          header: () => 'Reason',
          cell: ({ getValue }) => reasonLookUp[getValue()],

          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'action',
          header: () => 'Action',
          cell: (props) => {
            return (
              <>---</>
              // <div className="d--inline-block">
              //   <Dropdown
              //     closeOnClickOutside={true}
              //     dropList={{
              //       component: ({ item }) => {
              //         if (!item?.access) return;
              //         return (
              //           <div
              //             className="text--secondary p--sm w--full text--l"
              //             onClick={() =>
              //               handleProfileDropClick(
              //                 item?.value,
              //                 props.row?.original,
              //                 item,
              //               )
              //             }
              //           >
              //             {item?.title}
              //           </div>
              //         );
              //       },

              //       data: [
              //         {
              //           title: 'View Eligibility',
              //           value: 'view',
              //           id: 4,
              //           access: !props?.row?.original?.submittedDate
              //             ? false
              //             : props?.row?.original?.eligibilitySubmittedDate
              //             ? true
              //             : false,
              //         },
              //         {
              //           title: 'View Consent',
              //           value: 'view',
              //           id: 5,
              //           access: !props?.row?.original?.submittedDate
              //             ? false
              //             : props?.row?.original?.submittedDate
              //             ? true
              //             : false,
              //         },
              //         // { title: 'Edit', value: 'edit', id: 2, access: true },
              //         // {
              //         //   title: 'Delete',
              //         //   value: 'delete',
              //         //   id: 3,
              //         //   access: true,
              //         // },
              //       ],
              //     }}
              //   >
              //     {dropEl}
              //   </Dropdown>
              // </div>
            );
          },
          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
      },
    },
  ];

  const tableActions = [
    {
      id: 3,
      access: true,
      label: 'Upload To Drive',
      component: (
        <Button
          btnClasses="btn w-min--150   text--black white-space--nowrap gap--xs"
          variant="transparent"
          color="black"
          borderColor="primary"
          data-link-hover-primary
          isOutline
          onClick={() => {
            setOpenModal('upload_drive');
          }}
          icon={<UploadCloud width={18} />}
          disabled={!planDetails?.accessPermission}
        >
          Upload
        </Button>
      ),
    },
    {
      id: 2,
      access: ['windows', 'macos'].includes(getOS()),
      label: 'Import Member',
      component: (
        <Button
          btnClasses="btn w-min--150   text--black white-space--nowrap gap--xs"
          variant="transparent"
          color="black"
          borderColor="primary"
          data-link-hover-primary
          isOutline
          onClick={() => {
            setOpenModal('import_member');
          }}
          icon={<ImportIcon width={18} />}
          disabled={!planDetails?.accessPermission}
        >
          Import
        </Button>
      ),
    },
    {
      id: 1,
      access: true,
      label: 'Add Member',
      component: (
        <Button
          btnClasses="btn w-min--150   text--black gap--xs"
          variant="transparent"
          color="black"
          borderColor="primary"
          isOutline
          data-link-hover-primary
          onClick={() => {
            setOpenAsideModal(true);
            setEditMemberInstance(initialEditInstance);
          }}
          icon={<AddIcon width={16} height={16} />}
          disabled={!planDetails?.accessPermission}
        >
          Add Consumer
        </Button>
      ),
    },
  ];

  const modalLookUp = {
    upload_drive: {
      component: (
        <UploadToDrive {...{ handleClose: () => setOpenModal(false) }} />
      ),
      // title: 'Upload To Drive',
      title: 'Upload',
      // bodyBg: false,
    },
    import_member: {
      component: (
        <ImportMember {...{ handleClose: () => setOpenModal(false) }} />
      ),
      title: 'Import Consumer',
    },
    send_doc: {
      component: (
        <SendDocModal
          {...{
            handleClose: () => setOpenModal(false),
            uuid: editMemberInstance.editData?._id,
            editMemberInstance: editMemberInstance,
            isSigned: Boolean(editMemberInstance.editData?.signedDate),
          }}
        />
      ),
      title: 'Send Docs',
    },
    consent_doc: {
      component: (
        <UploadConsentDoc
          {...{
            handleClose: () => setOpenModal(false),
            uuId: editMemberInstance.editData?._id,
            editMemberInstance: editMemberInstance,
            docType: 'CONSENT',
          }}
        />
      ),
      title: 'Upload Consent Docs',
    },
    eligibility_doc: {
      component: (
        <UploadConsentDoc
          {...{
            handleClose: () => setOpenModal(false),
            uuId: editMemberInstance.editData?._id,
            editMemberInstance: editMemberInstance,
            docType: 'ELIGIBILITY',
          }}
        />
      ),
      title: 'Upload Eligibility Docs',
    },
  };

  return (
    <>
      <div className="w--full d--flex flex--column gap--lg memberPage">
        <div className="w--full thead-doubble-tr">
          <TableManager
            {...{
              fetcherKey: 'getMemberList',
              fetcherFn: getMemberList,
              shouldFilter: false,
              name: 'Consumer',
              columns: columns,
              tableActions,
              selectCBForTableData: (tblData) =>
                tblData?.data?.data?.memberResponseData,
              shouldRefresh: true,
              enableMoreOption: true,
              showMoreOption: (_) => _?.enrollment > 0,
              moreDataFunction: getMemberEnrollmentList,
              moreDataFnPayload: (_) => ({ email: _?.email }),
              moreOptioncolumns,
              filterDefaultValues,
            }}
          />
        </div>
      </div>

      {openAsideModal ? (
        <AsideModal
          handleClose={handleClose}
          title={`${
            openAsideModal === 'HRA'
              ? 'HRA'
              : !editMemberInstance?.isEdit
              ? 'Add Consumer'
              : 'Edit Consumer'
          } `}
          footerComponent={null}
          headerComponent={null}
          size={openAsideModal === 'HRA' ? 'lg' : '500'}
          width={openAsideModal === 'HRA' ? 'lg' : '500'}
          // width="md"
        >
          {openAsideModal === 'HRA' ? (
            <HRAView
              handleClose={handleClose}
              editMemberInstance={editMemberInstance}
            />
          ) : (
            <AddMember
              handleClose={handleClose}
              editMemberInstance={editMemberInstance}
            />
          )}
        </AsideModal>
      ) : null}

      {openModal ? (
        <Modal
          width="md"
          height="200"
          size="600"
          shouldCloseOnClickOutside={false}
          handleClose={handleClose}
          headerComponent={null}
          footerComponent={null}
          title={modalLookUp?.[openModal]?.title}
          bodyBg={modalLookUp?.[openModal]?.bodyBg}
        >
          {modalLookUp?.[openModal]?.component}
        </Modal>
      ) : null}

      {drive?.isOpen ? (
        <Modal
          title={'Upload to Drive'}
          headerComponent={null}
          footerComponent={null}
          handleClose={() => setDrive(initialDriveState)}
          shouldCloseOnClickOutside={false}
          width="md"
          height="200"
          size="600"
        >
          <UploadDrive data={drive?.data} setDrive={setDrive} />
        </Modal>
      ) : null}
    </>
  );
};

export default Members;
